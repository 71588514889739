@use "sass:color";@use 'roedl' as *;.VariantObjectWizard {
  &.right-margin {
    margin-right: 10px;
  }
}

.variant-object-wizard-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .custom-form-controls-inner {
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-start;

    :not(:first-child) {
      margin-left: unset !important;
      margin-right: 0.25rem;
    }

    :not(:last-child) {
      margin-right: unset !important;
    }
  }

  > button:not(:last-child) {
    margin-right: 0.25rem;
  }
}
